import Head from 'next/head'
import { useRouter } from 'next/router'
import { FALLBACK_IMAGE_URL } from '../utils/constants'

export const genericMetadata = {
  TITLE: 'Cheddar',
  DESCRIPTION:
    "Cheddar is a leading source of financial journalism, technology coverage, and stories of innovation.",
  THUMBNAIL: {
    url: FALLBACK_IMAGE_URL,
    height: 675,
    width: 1200
  }
}

const Metadata = ({ metadata = {} }) => {
  const {
    title = genericMetadata.TITLE,
    thumbnail = genericMetadata.THUMBNAIL,
    description = genericMetadata.DESCRIPTION,
    keywords = null
  } = metadata
  const router = useRouter()

  return (
    <Head>
      <title>{title}</title>
      <link rel='canonical' href={`https://cheddar.com${router?.asPath?.split('?')[0]}`} />
      <meta property='description' content={description} />
      <meta property='keywords' content={keywords?.join(', ')} />

      <meta property='og:site_name' content='Cheddar' />
      <meta property='og:type' content='website' />
      <meta property='og:title' content={title} />
      <meta property='og:description' content={description} />
      <meta property='og:image' content={thumbnail.url} />
      <meta property='og:image:width' content='1200' />
      <meta property='og:image:height' content='675' />

      <meta property='twitter:site' content='@cheddar' />
      <meta property='twitter:dnt' content='on' />
      <meta property='twitter:card' content='summary_large_image' />
      <meta property='twitter:title' content={title} />
      <meta property='twitter:description' content={description} />
      <meta property='twitter:image:src' content={thumbnail.url} />
    </Head>
  )
}

export default Metadata
