import { faPlay } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import 'moment-duration-format'
import Icon from './Icon'
import style from '../styles/components/duration-pill'

const DurationPill = ({ story }) => (
  <>
    <div className='duration-pill'>
      <Icon icon={faPlay} color='black' size='sm' width='16px' />
      <span>{moment.duration(story?.duration, 'seconds').format()}</span>
    </div>
    <style jsx>{style}</style>
  </>
)

export default DurationPill
