export const STORIES_PER_PAGE = 10
export const STORIES_PER_SHOWPAGE = 6

export const FALLBACK_IMAGE_URL =
  'https://images-cf.news12static.com/vcjvsnaeixme/1Ve14WVexCdDpW8YNYxQsl/a1033c4ab81021747c29d6744c06c70a/cheddar-logo-UPDATED.png'

export const IMAGE_BLUR_URL =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mO8duRMPQAHxQLn8PBE4QAAAABJRU5ErkJggg=='

export const DEFAULT_AVATAR =
  'https://images-cf.news12static.com/vcjvsnaeixme/6R1GSqFAwASM6JKV042lJ9/0bedb9fe30e4432c6687eaff419c9c82/cheddar-icon-UPDATED.png?fit=thumb&w=400&q=50'

export const CONTENTFUL_ENVIRONMENTS = { MASTER: 'master', STAGING: 'staging', DEV: 'dev' }
