import MediaCard from './MediaCard'
import style from '../../styles/components/home-page/home-page'

const Row = ({ section, layout }) => (
  <>
    <div className='row'>
      {section?.slice(0, layout ? 3 : 4).map((story) => (
        <MediaCard key={story.slug} story={story} layout={layout} />
      ))}
    </div>
    <style jsx>{style}</style>
  </>
)

export default Row
