import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import Icon from './Icon'
import style from '../styles/components/breaking-news-banner'

const BreakingNewsBanner = ({ banner }) => (
  <>
    <div className='banner-container'>
      <a href={`/media/${banner.slug}`}>
        <h3>{banner.tagline}</h3>
        <h1>{banner.title}</h1>
        <div className='cta'>
          {banner.cta}
          <Icon icon={faArrowRight} />
        </div>
      </a>
    </div>
    <style jsx>{style}</style>
  </>
)

export default BreakingNewsBanner
