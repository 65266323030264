import 'moment-duration-format'
import moment from 'moment'
import Image from 'next/image'
import { IMAGE_BLUR_URL } from '../../utils/constants'
import timeAgo from '../../utils/timeAgo'
import DurationPill from '../DurationPill'
import style from '../../styles/components/media-card'

const MediaCard = ({ story, layout, showFullDate = false }) => {
  return (
    <>
      <div className={`card-container ${layout}`}>
        <a href={`/media/${story.slug}`}>
          {story?.duration && (
            <div className='duration'>
              <DurationPill story={story} />
            </div>
          )}
          <Image
            src={story?.thumbnail?.url}
            width={story?.thumbnail.width}
            height={story?.thumbnail.height}
            alt={story?.title}
            layout='responsive'
            placeholder='blur'
            blurDataURL={IMAGE_BLUR_URL}
          />
          <div className='card-title'>{story?.title}</div>
          {showFullDate ? (
            <div className='time-stamp'>{moment(story?.updatedAt).format('MMMM D, YYYY')}</div>
          ) : (
            <div className='time-stamp'>{timeAgo(story?.updatedAt)}</div>
          )}
        </a>
      </div>
      <style jsx>{style}</style>
    </>
  )
}

export default MediaCard
